import { theme, extendTheme } from '@chakra-ui/react';

import { button } from './components';
import styles from './styles';

// https://chakra-ui.com/docs/theming/customize-theme
const customTheme = extendTheme(
  // Override the global styles with our own
  {
    styles: styles,
    sizes: {
      content: {
        sm: '380px',
        md: '600px',
        lg: '700px',
      },
    },
  },
  // Override the button theme with our own
  {
    components: {
      Button: button,
    },
    Container: {
      baseStyle: {
        paddingX: {
          base: 6,
          md: 8,
          lg: 16,
          xl: 24,
          prexl: 0,
        },
      },
    },
  },
  // Base theme to override (usually the default or one generated by figma2theme)
  theme
);
export type Theme = typeof customTheme;

export default customTheme;
