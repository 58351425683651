import React from 'react';

import theme from '@/theme';
import {
  ChakraProvider,
  Text,
  Box,
  OrderedList,
  UnorderedList,
  ListItem,
  Divider,
  Code,
} from '@chakra-ui/react';
import type {
  TextProps,
  BoxProps,
  ListProps,
  ListItemProps,
  DividerProps,
} from '@chakra-ui/react';
import { MDXProvider } from '@mdx-js/react';

import { Image } from '@/theme/chakra-elements';

const components = {
  img: Image,
  h1: (props: TextProps) => (
    <Text
      as="h1"
      fontSize={{ base: '30px', md: '50px' }}
      marginTop={0}
      marginBottom={8}
      {...props}
    />
  ),
  h2: (props: TextProps) => (
    <Text
      as="h2"
      fontSize={{ base: '24px', md: '40px' }}
      marginTop={6}
      marginBottom={8}
      {...props}
    />
  ),
  h3: (props: TextProps) => (
    <Text
      as="h3"
      fontSize={{ base: '20px', md: '28px' }}
      marginBottom={4}
      {...props}
    />
  ),
  ol: (props: ListProps) => (
    <OrderedList marginLeft={6} marginBottom={8} {...props} />
  ),
  ul: (props: ListProps) => (
    <UnorderedList marginLeft={6} marginBottom={8} {...props} />
  ),
  li: (props: ListItemProps) => (
    <ListItem
      fontSize={{ md: '18px', lg: '20px' }}
      marginBottom={2}
      {...props}
    />
  ),
  hr: (props: DividerProps) => <Divider marginBottom={4} {...props} />,
  p: (props: TextProps) => (
    <Text fontSize={{ md: '18px', lg: '20px' }} marginBottom={4} {...props} />
  ),
  i: ({ children, ...props }: BoxProps) => (
    <Box
      display="inline-flex"
      width={{ base: '60px', md: '80px' }}
      height={{ base: '60px', md: '80px' }}
      border="2px solid #cfcfcf"
      background="linear-gradient(90deg, #716b9d 0%, #39487E 80%)"
      justifyContent="center"
      alignItems="center"
      borderRadius="50%"
      marginRight={{ base: 2, md: 4 }}
      zIndex="2"
      position="relative"
      overflow="hidden"
      {...props}
    >
      <Box filter="grayscale(1)">{children}</Box>
    </Box>
  ),
  inlineCode: Code,
};

type Props = {
  children: React.ReactNode;
};

const AppProvider = ({ children }: Props) => {
  return (
    <MDXProvider components={components}>
      <ChakraProvider theme={theme}>{children}</ChakraProvider>
    </MDXProvider>
  );
};

export default AppProvider;
