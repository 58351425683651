import type { Styles } from '@chakra-ui/theme-tools';

// Extend the default global styles
// https://chakra-ui.com/docs/features/global-styles
const styles: Styles = {
  global: {
    // Make our page containers fill the height of the browser
    'html, body, #root, #__next': {
      height: '100%',
      fontFamily: 'Noto Sans',
    },
    h1: {
      fontFamily: 'Space Mono',
    },
    'h2, h3, h4, h5': {
      // fontFamily,
    },
  },
};

export default styles;
