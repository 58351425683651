export const isExternalLink = (link: string) => {
  // If host name exists in the url then return false
  if (typeof window !== 'undefined') {
    const hostNameRegEx = new RegExp(`${window.location.hostname}`, 'i');
    if (hostNameRegEx.test(link)) {
      return false;
    }
  }
  // Check whether http:// or https:// exists
  return /(http(s?)):\/\//i.test(link);
};
