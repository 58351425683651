import React from 'react';

import { Button, chakra, Link as ChakraLink, Text } from '@chakra-ui/react';
import type { LinkProps, ButtonProps, BoxProps } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';

import NextImage from 'next/image';
import NextLink from 'next/link';
import { isExternalLink } from '@/utils/helpers';

export const Link: React.FC<LinkProps> = ({
  children,
  href = '/',
  ...rest
}) => {
  if (!href) {
    return <Text>Href has not been set</Text>;
  }

  if (isExternalLink(href)) {
    return (
      <ChakraLink href={href} target="_blank" rel="noopener" {...rest}>
        {children}
      </ChakraLink>
    );
  }

  return (
    <NextLink href={href} passHref>
      <ChakraLink href={href} {...rest}>
        {children}
      </ChakraLink>
    </NextLink>
  );
};

export const LinkButton: React.FC<ButtonProps & { href?: string }> = ({
  children,
  href,
  ...rest
}) => {
  if (!href) {
    return <Text>Href has not been set</Text>;
  }

  if (isExternalLink(href)) {
    return (
      <Button
        as="a"
        rightIcon={<ExternalLinkIcon />}
        target={'_blank'}
        href={href ?? '/'}
        rel="noopener"
        {...rest}
      >
        {children}
      </Button>
    );
  }

  return (
    <NextLink href={href} passHref>
      <Button as="a" target={'_self'} href={href ?? '/'} {...rest}>
        {children}
      </Button>
    </NextLink>
  );
};

const ChakraNextImage = chakra(NextImage);

type ChakraNextImageProps = BoxProps & React.ComponentProps<typeof NextImage>;

export const Image: React.FC<ChakraNextImageProps> = (props) => {
  const { src, width, height, ...rest } = props;
  // Fallback if no width height is entered

  if (typeof src === 'string') {
    if (!width || !height) {
      return <ChakraNextImage src={src} unoptimized />;
    }
    return (
      <ChakraNextImage
        src={{ src: String(src), height: Number(height), width: Number(width) }}
        {...rest}
      />
    );
  }

  return (
    <ChakraNextImage
      src={{ ...src, width: Number(width), height: Number(height) }}
      {...rest}
    />
  );
};
